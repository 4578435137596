import React, { useEffect, useState } from 'react';
import './App.css';
import StatusCard from './StatusCard';

function App() {
  const [statusData, setStatusData] = useState({});

  useEffect(() => {
    // Function to fetch status data
    const fetchStatusData = async () => {
      try {
        const response = await fetch('https://statusapi.hybridhosting.co/status');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setStatusData(data);
      } catch (error) {
        console.error("Failed to fetch status data:", error);
      }
    };

    // Call the function
    fetchStatusData();
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <div className="App">
      <header className="App-header">
        <h1>Hybrid Hosting Status Page</h1>
      </header>
      <div className="Status-container">
        {Object.entries(statusData).map(([ip, data]) => {
          let name;
          switch (ip) {
            case '167.94.253.32':
              name = 'Infrastructure';
              break;
            case '193.243.190.138':
              name = 'MC';
              break;
            case '213.170.135.216':
              name = 'Backups';
              break;
            default:
              name = `Service ${ip}`;
          }
          return <StatusCard key={ip} name={name} status={data.status} lastChecked={data.last_checked} />;
        })}
      </div>
    </div>
  );
}

export default App;