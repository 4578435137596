import React from 'react';

const StatusCard = ({ name, status, lastChecked }) => {
  return (
    <div className="status-card">
      <h3>{name}</h3>
      <p>Last Checked: {lastChecked}</p>
      <div className="status-indicator">
        <span className={status === 'up' ? 'status-circle up' : 'status-circle down'}></span>
        {status === 'up' ? 'Up' : 'Down'}
      </div>
    </div>
  );
};

export default StatusCard;